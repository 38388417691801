import AuthClient from '@/services/users/auth'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'
import {STATUS} from '@/store/status'

const state = {
	refreshToken: localStorage.getItem('WEBADMIN_REFRESH_TOKEN'),
	loggedIn: false,
	user: {
		name: '',
		username: '',
		email: '',
		jwt: '',
	},
	refreshing: false,
	refreshCall: null,

	loginStatus: STATUS.NOT_RUN,
}

const actions = {
	login: async ({commit, dispatch}, data) => {
		const processName = 'Authenticating'
		const statusName = 'SET_LOGIN_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await AuthClient.login(data)
			commit('SET_USER', resp.data)
			commit('SET_REFRESH_TOKEN', resp.data.refreshToken)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	logout: async ({commit, dispatch, getters}) => {
		const processName = 'Logging Out'
		addProcess(dispatch, processName)
		try {
			await AuthClient.logout(getters['refreshToken'])
		} catch (e) {
			alertAPIError(dispatch, e)
		} finally {
			dispatch('clearUserData')
			removeProcess(dispatch, processName)
		}
	},
	refresh: ({commit, dispatch, getters}) => {
		if (getters['refreshing']) {
			return getters['refreshCall']
		}
		let ref = new Promise(async (resolve, reject) => {
			try {
				let resp = await AuthClient.refresh(getters['refreshToken'])
				commit('SET_USER', resp.data)
				resolve()
			} catch (e) {
				dispatch('clearUserData')
				reject(e)
			} finally {
				commit('SET_REFRESHING', false)
			}
		})
		commit('SET_REFRESHING', true)
		commit('SET_REFRESH_CALL', ref)
		return ref
	},
	clearUserData: ({commit}) => {
		commit('SET_USER', {})
		commit('CLEAR_REFRESH_TOKEN')
	}
}

const getters = {
	name: state => state.user.name,
	jwt: state => state.user.jwt,
	refreshToken: state => state.refreshToken,
	refreshing: state => state.refreshing,
	refreshCall: state => state.refreshCall,
	loggedIn: state => state.refreshToken && state.user.jwt
}

const mutations = {
	SET_USER: (state, payload) => state.user = payload,
	SET_REFRESH_TOKEN: (state, payload) => {
		state.refreshToken = payload
		localStorage.setItem('WEBADMIN_REFRESH_TOKEN', payload)
	},
	SET_LOGIN_STATUS: (state, payload) => state.loginStatus = payload,
	SET_REFRESHING: (state, payload) => state.refreshing = payload,
	SET_REFRESH_CALL: (state, payload) => state.refreshCall = payload,

	CLEAR_REFRESH_TOKEN: (state) => {
		state.refreshToken = null
		localStorage.removeItem('WEBADMIN_REFRESH_TOKEN')
	},
}

export default {
	namespaced: true,
	state,
	actions,
	getters,
	mutations
}
