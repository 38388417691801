export const modules = [
	{
		name: 'Accessories',
		link: '/accessories',
		icon: 'mdi-floor-lamp',
	},
	{
		name: 'Appliances',
		link: '/appliances',
		icon: 'mdi-washing-machine',
	},
	/*{
		name: 'Blog',
		link: '/blog',
		icon: 'mdi-post',
		navItems: [
			{
				name: 'Categories',
				link: 'categories'
			},
			{
				name: 'Posts',
				link: 'posts'
			},
		]
	},*/
	{
		name: 'Featured',
		link: '/featured',
		icon: 'mdi-movie',
	},
	{
		name: 'Galleries',
		link: '/galleries',
		icon: 'mdi-folder-multiple-image'
	},
]
