import Vue from 'vue'
import Vuetify from 'vuetify/lib/framework'

Vue.use(Vuetify)

const options = {
	theme: {
		options: {
			customProperties: true,
		},
		themes: {
			light: {
				primary: '#3949AB',
				secondary: '#5C6BC0',
				accent: '#536DFE',
				error: '#FF5252',
				info: '#2196F3',
				success: '#4CAF50',
				warning: '#FF9800',
				background: '#FAFAFA',
			},
			dark: {
				primary: '#536DFE',
				secondary: '#448AFF',
				accent: '#82B1FF',
				error: '#FF5252',
				info: '#2196F3',
				success: '#00E676',
				warning: '#FF9800',
				background: '#121212',
			},
		},
	},
	icons: {
		iconfont: 'mdi',
	},
}

export default new Vuetify({
	...options,
})
