import GalleryClient from '@/services/gallery/gallery'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const state = {
	galleries: [],
	gallery: {},

	listStatus: STATUS.NOT_RUN,
	readStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	archiveStatus: STATUS.NOT_RUN,
}

const actions = {
	listGalleries: async ({commit, dispatch, getters}) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Galleries'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await GalleryClient.list()
			commit('SET_GALLERIES', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	readGallery: async ({commit, dispatch, getters}, id) => {
		if (getters['readStatus'] === STATUS.RUNNING) return

		const processName = 'Getting Galleries'
		const statusName = 'SET_READ_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await GalleryClient.read(id)
			commit('SET_GALLERY', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createGallery: async ({commit, dispatch, getters}, data) => {
		if (getters['createStatus'] === STATUS.RUNNING) return

		const processName = 'Creating Gallery'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryClient.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	updateGallery: async ({commit, dispatch, getters}, data) => {
		if (getters['updateStatus'] === STATUS.RUNNING) return

		const processName = 'Updating Gallery'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryClient.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	archiveGallery: async ({commit, dispatch, getters}, id) => {
		if (getters['archiveStatus'] === STATUS.RUNNING) return

		const processName = 'Archiving Gallery'
		const statusName = 'SET_ARCHIVE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryClient.archive(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
}

const mutations = {
	SET_GALLERIES: (state, payload) => state.galleries = payload,
	SET_GALLERY: (state, payload) => state.gallery = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_READ_STATUS: (state, payload) => state.readStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_ARCHIVE_STATUS: (state, payload) => state.archiveStatus = payload,
}

const getters = {
	galleries: state => state.galleries,
	gallery: state => state.gallery,
	listStatus: state => state.listStatus,
	readStatus: state => state.readStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	archiveStatus: state => state.archiveStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
