import process from '@/store/global/process'
import alert from '@/store/global/alert'

export default {
	namespaced: true,
	modules: {
		process,
		alert
	}
}
