export default {
	methods: {},
	computed: {
		mobileLayout() {
			return this.$vuetify.breakpoint.mobile
		},
		cardElevation() {
			return this.$vuetify.theme.dark ? 6 : 4
		},
		customCard() {
			if (this.$vuetify.theme.dark) {
				return {
					elevation: 6,
					outlined: true,
				}
			}
			return {
				elevation: 4,
				outlined: false,
			}
		}
	}
}
