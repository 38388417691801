import FileClient from '@/services/media/file'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const state = {
	roots: [],
	meta: {
		baseUrl: '',
	},
	uploadErrors: [],

	listStatus: STATUS.NOT_RUN,
	readStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	uploadStatus: STATUS.NOT_RUN,
	archiveStatus: STATUS.NOT_RUN,
}

const actions = {
	getMeta: async ({commit, dispatch}) => {
		try {
			let resp = await FileClient.meta()
			commit('SET_META', resp.data)
		} catch (e) {
			alertAPIError(dispatch, e)
		}
	},
	listRoots: async ({commit, dispatch, getters}) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Root Folders'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await FileClient.roots()
			commit('SET_ROOTS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	listDetails: ({commit, dispatch, getters}, data) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Folders'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		return new Promise(async (resolve, _) => {
			try {
				let resp = await FileClient.list(data)
				resolve(resp.data)
				commit(statusName, STATUS.SUCCEEDED)
			} catch (e) {
				alertAPIError(dispatch, e)
				commit(statusName, STATUS.FAILED)
			} finally {
				removeProcess(dispatch, processName)
			}
		})
	},
	upload: async ({commit, dispatch, getters}, data) => {
		if (getters['uploadStatus'] === STATUS.RUNNING) return

		const processName = 'Uploading Files'
		const statusName = 'SET_UPLOAD_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await FileClient.upload(data.uploadPath, data.files)
			commit('SET_UPLOAD_ERRORS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	}
}

const getters = {
	meta: state => state.meta,
	roots: state => state.roots,
	uploadErrors: state => state.uploadErrors,
	listStatus: state => state.listStatus,
	uploadStatus: state => state.uploadStatus,
}

const mutations = {
	SET_META: (state, payload) => state.meta = payload,
	SET_ROOTS: (state, payload) => state.roots = payload,
	SET_UPLOAD_ERRORS: (state, payload) => state.uploadErrors = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_UPLOAD_STATUS: (state, payload) => state.uploadStatus = payload,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
