<template>
	<div>
		<v-row v-for="item in items" :key="item.name" :class="nested ? 'ml-2' : ''" align="center" dense>
			<v-col class="d-flex flex-row align-center body-1 text--primary" cols="12">
				<v-icon @click="toggleOpen(item)" class="mr-2"
						v-text="openKeys.indexOf(item.name) !== -1 ? 'mdi-folder-open' : 'mdi-folder'"></v-icon>
				<div @click="selectFolder(item)" class="folder-name">{{ item.name }}</div>

			</v-col>
			<v-col v-if="item.folders.length > 0 && openKeys.indexOf(item.name) !== -1" cols="12">
				<v-expand-transition>
					<file-tree :items="item.folders" @openChanged="openChanged" nested></file-tree>
				</v-expand-transition>
			</v-col>
		</v-row>
	</div>
</template>

<script>
import {mapActions} from 'vuex'

export default {
	name: 'FileTree',
	props: {
		items: {type: Array},
		nested: {type: Boolean, default: false},
		activePath: {type: String}
	},
	data: () => ({
		open: [],
		openKeys: [],
		openFullPath: '',
	}),
	methods: {
		...mapActions('media/file', [
			'listDetails'
		]),
		toggleOpen(item) {
			const idx = this.openKeys.indexOf(item.name)
			if (idx === -1) {
				this.openFullPath = item.fullPath
				this.openKeys.push(item.name)
				if (item.folders.length === 0) {
					this.listDetails({rootPath: item.fullPath}).then(res => {
						item.folders = res[0].folders
						item.files = res[0].files
						this.openChanged(item)
					})
				} else {
					this.openChanged(item)
				}
			} else {
				this.openKeys.splice(idx, 1)
			}
			document.activeElement.blur()
		},
		selectFolder(item) {
			const idx = this.openKeys.indexOf(item.name)
			if (idx === -1) {
				this.openFullPath = item.fullPath
				this.openKeys.push(item.name)
				if (item.folders.length === 0) {
					this.listDetails({rootPath: item.fullPath}).then(res => {
						item.folders = res[0].folders
						item.files = res[0].files
						this.openChanged(item)
					})
				} else {
					this.openChanged(item)
				}
			} else {
				this.openChanged(item)
			}
		},
		openChanged(item) {
			this.openFullPath = item.fullPath
			this.$emit('openChanged', item)
		}
	},
	computed: {}
}
</script>

<style scoped>
.folder-name {
	cursor: pointer;
}
</style>
