import Client from '@/services/accessory/product'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const defaultFilters = {
	page: 1,
	limit: 10,
	name: null,
	sku: null,
	category: null,
	archived: false,
}

const state = {
	products: {
		data: [],
		pages: 0,
		total: 0,
	},
	product: {},
	appliedFilters: {...defaultFilters},

	listStatus: STATUS.NOT_RUN,
	readStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	archiveStatus: STATUS.NOT_RUN,
}

const actions = {
	applyFilters: ({commit, dispatch}, data) => {
		commit('SET_APPLIED_FILTERS', data)
		dispatch('listProducts')
	},
	listProducts: async ({commit, dispatch, state}) => {
		if (state.listStatus === STATUS.RUNNING) return

		const processName = 'Listing Products'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.list(state.appliedFilters)
			commit('SET_PRODUCTS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	readProduct: ({commit, dispatch}, data) => {
		const processName = 'Get Product'
		addProcess(dispatch, processName)
		return new Promise(async (resolve, reject) => {
			try {
				let resp = await Client.read(data)
				commit('SET_PRODUCT', resp.data)
				resolve()
			} catch (e) {
				alertAPIError(dispatch, e)
				reject()
			} finally {
				removeProcess(dispatch, processName)
			}
		})
	},
}

const mutations = {
	SET_PRODUCTS: (state, payload) => state.products = payload,
	SET_PRODUCT: (state, payload) => state.product = payload,
	SET_APPLIED_FILTERS: (state, payload) => state.appliedFilters = payload,

	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_READ_STATUS: (state, payload) => state.readStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_ARCHIVE_STATUS: (state, payload) => state.archiveStatus = payload,
}

const getters = {
	products: state => state.products,
	product: state => state.product,
	appliedFilters: state => state.appliedFilters,

	listStatus: state => state.listStatus,
	readStatus: state => state.readStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	archiveStatus: state => state.archiveStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
