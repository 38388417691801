import accessoryGroup from '@/store/accessory/accessory-group'
import category from '@/store/accessory/category'
import product from '@/store/accessory/product'

export default {
	namespaced: true,
	modules: {
		accessoryGroup,
		category,
		product,
	}
}
