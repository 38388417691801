import {ADMIN_CONFIG} from '@/config'
import store from '@/store'

const axios = require('axios')

const client = axios.create({
	baseURL: ADMIN_CONFIG.BASE_URL,
})

client.interceptors.request.use(config =>{
	config.headers.common['Authorization'] = `Bearer ${store.getters['auth/jwt']}`
	return config
})

client.interceptors.response.use(
	response => {
		return response
	},
	error => {
		if (!error.response) {
			return Promise.reject(error)
		}
		if (error.response.status === 401 && error.response.data) {
			let err = error.response.data
			if (err.type === 'InvalidToken' || err.type === 'ExpiredToken') {
				return store.dispatch('auth/refresh').then(_ => {
					error.config.headers['Authorization'] = `Bearer ${store.getters['auth/jwt']}`
					return client.request(error.config);
				})
			}
		}
		return Promise.reject(error)
	}
)


export default client
