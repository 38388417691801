import client from '@/services/client'

const base = '/accessory/admin'

export default {

	list: (query) => {
		return client.get(`${base}/accessory-groups`, {params: query})
	},

	create: (data) => {
		return client.post(`${base}/accessory-group`, data)
	},

	update: (data) => {
		return client.patch(`${base}/accessory-group`, data)
	},

	archived: () => {
		return client.get(`${base}/accessory-groups/archived`)
	},

	archive: (id) => {
		return client.delete(`${base}/accessory-group/${id}/archive`)
	},

	restore: (id) => {
		return client.patch(`${base}/accessory-group/${id}/restore`)
	},

}
