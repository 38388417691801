import Client from '@/services/accessory/category'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const defaultFilters = {
	page: 1,
	limit: 10,
	name: null,
	accessoryGroup: null,
	archived: false,
}

const state = {
	categories: {
		data: [],
		pages: 0,
		total: 0,
	},
	appliedFilters: {...defaultFilters},

	listStatus: STATUS.NOT_RUN,
	readStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	archiveStatus: STATUS.NOT_RUN,
	restoreStatus: STATUS.NOT_RUN,
}

const actions = {
	applyFilters: ({commit, dispatch}, data) => {
		commit('SET_APPLIED_FILTERS', data)
		dispatch('listCategories')
	},
	listCategories: async ({commit, dispatch, state}) => {
		if (state.listStatus === STATUS.RUNNING) return

		const processName = 'Listing Categories'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.list(state.appliedFilters)
			commit('SET_CATEGORIES', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	listAllCategories: async ({commit, dispatch, state}) => {
		if (state.listStatus === STATUS.RUNNING) return

		const processName = 'Listing Categories'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.list({})
			commit('SET_CATEGORIES', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createCategory: async ({commit, dispatch, state}, data) => {
		if (state.createStatus === STATUS.RUNNING) return

		const processName = 'Creating Category'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	updateCategory: async ({commit, dispatch, state}, data) => {
		if (state.updateStatus === STATUS.RUNNING) return

		const processName = 'Updating Category'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	archivedCategories: async ({commit, dispatch, getters}) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Archived Categories'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.archived()
			commit('SET_CATEGORIES', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	archiveCategory: async ({commit, dispatch, state}, id) => {
		if (state.archiveStatus === STATUS.RUNNING) return

		const processName = 'Archiving Category'
		const statusName = 'SET_ARCHIVE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.archive(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	restoreCategory: async ({commit, dispatch, state}, id) => {
		if (state.restoreStatus === STATUS.RUNNING) return

		const processName = 'Restoring Category'
		const statusName = 'SET_RESTORE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.restore(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
}

const mutations = {
	SET_CATEGORIES: (state, payload) => state.categories = payload,
	SET_APPLIED_FILTERS: (state, payload) => state.appliedFilters = payload,

	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_READ_STATUS: (state, payload) => state.readStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_ARCHIVE_STATUS: (state, payload) => state.archiveStatus = payload,
	SET_RESTORE_STATUS: (state, payload) => state.restoreStatus = payload,
}

const getters = {
	categories: state => state.categories,
	appliedFilters: state => state.appliedFilters,

	listStatus: state => state.listStatus,
	readStatus: state => state.readStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	archiveStatus: state => state.archiveStatus,
	restoreStatus: state => state.restoreStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
