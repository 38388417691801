import GalleryImageClient from '@/services/gallery/gallery-image'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const state = {
	galleryImages: [],

	listStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	resequenceStatus: STATUS.NOT_RUN,
	uploadStatus: STATUS.NOT_RUN,
	destroyStatus: STATUS.NOT_RUN,
}

const actions = {
	listGalleryImages: async ({commit, dispatch, getters}, id) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Gallery Images'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await GalleryImageClient.list(id)
			commit('SET_GALLERY_IMAGES', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createGalleryImage: async ({commit, dispatch, getters}, data) => {
		if (getters['createStatus'] === STATUS.RUNNING) return

		const processName = 'Creating Gallery Image'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryImageClient.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	updateGalleryImage: async ({commit, dispatch, getters}, data) => {
		if (getters['updateStatus'] === STATUS.RUNNING) return

		const processName = 'Updating Gallery Image'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryImageClient.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	uploadGalleryImage: async ({commit, dispatch, getters}, data) => {
		if (getters['uploadStatus'] === STATUS.RUNNING) return

		const processName = 'Uploading Gallery Image'
		const statusName = 'SET_UPLOAD_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryImageClient.upload(data.id, data.file)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	resequenceGalleryImages: async ({commit, dispatch, getters}, data) => {
		if (getters['resequenceStatus'] === STATUS.RUNNING) return

		const processName = 'Resequencing Gallery Images'
		const statusName = 'SET_RESEQUENCE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryImageClient.resequence(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	destroyGalleryImage: async ({commit, dispatch, getters}, id) => {
		if (getters['destroyStatus'] === STATUS.RUNNING) return

		const processName = 'Deleting Gallery Image'
		const statusName = 'SET_DESTROY_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await GalleryImageClient.destroy(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
}

const mutations = {
	SET_GALLERY_IMAGES: (state, payload) => state.galleryImages = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_RESEQUENCE_STATUS: (state, payload) => state.resequenceStatus = payload,
	SET_UPLOAD_STATUS: (state, payload) => state.uploadStatus = payload,
	SET_DESTROY_STATUS: (state, payload) => state.destroyStatus = payload,
}

const getters = {
	galleryImages: state => state.galleryImages,
	listStatus: state => state.listStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	resequenceStatus: state => state.resequenceStatus,
	uploadStatus: state => state.uploadStatus,
	destroyStatus: state => state.destroyStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
