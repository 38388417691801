import client from '@/services/client'

const base = '/media'

export default {
	meta: () => {
		return client.get(`${base}/meta`)
	},
	roots: () => {
		return client.get(`${base}/files`)
	},
	list: (params) => {
		return client.get(`${base}/files`, {params: params})
	},
	upload: (path, files) => {
		let formData = new FormData()
		formData.append('path', path)
		files.forEach(f => formData.append('upload[]', f))
		// formData.append('upload[]', files)
		return client.post(`${base}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
	},
}
