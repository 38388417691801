import {v4 as uuidv4} from 'uuid'

export default {
	methods: {
		newUUID() {
			return uuidv4()
		},
		scrollToElement(id) {
			document.getElementById(id).scrollIntoView({behavior: 'smooth'})
		},
	},
}
