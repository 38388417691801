import item from '@/store/featured/item'
import page from '@/store/featured/page'

export default {
	namespaced: true,
	modules: {
		item,
		page,
	}
}
