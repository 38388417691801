import {beforeEdit, checkLoggedIn} from '@/router/_middleware'

const meta = {moduleName: 'Appliances', hideNav: false}

export const appliances = [
	{
		path: '/appliances',
		component: () => import( '@/views/appliances/Index.vue'),
		meta: meta,
		beforeEnter: checkLoggedIn,
		children: [
			{
				path: 'brand-groups',
				component: () => import( '@/views/appliances/brand-groups/Index'),
				name: 'Brand Groups',
				meta: {...meta, title: 'Brand Groups'},
			},
			{
				path: 'brands',
				component: () => import( '@/views/appliances/brands/Index'),
				name: 'Brands',
				meta: {...meta, title: 'Brands'},
			},
			{
				path: '',
				redirect: 'brand-groups',
			},
		]
	},
	{
		path: '/appliances/brand/:id',
		component: () => import( '@/views/appliances/brands/Edit'),
		name: 'Edit Brand',
		beforeEnter: beforeEdit,
		meta: {
			...meta,
			title: 'Edit Brand',
			action: 'appliance/brand/readBrand',
			fallback: 'Brands',
			hideNav: true
		},
	},
]
