import client from '@/services/client'

export default {
	login: (data) => {
		return client.post('/users/auth/', data)
	},
	logout: (data) => {
		return client.delete('/users/auth/', {headers: {Authorization: `Bearer: ${data}`}})
	},
	refresh: (data) => {
		return client.get('/users/auth/', {headers: {Authorization: `Bearer: ${data}`}})
	},
}
