import client from '@/services/client'

const base = '/gallery/admin'

export default {
	list: () => {
		return client.get(`${base}/galleries`)
	},
	read: (id) => {
		return client.get(`${base}/gallery/${id}`)
	},
	create: (data) => {
		return client.post(`${base}/gallery`, data)
	},
	update: (data) => {
		return client.patch(`${base}/gallery`, data)
	},
	archive: (id) => {
		return client.delete(`${base}/gallery/${id}/archive`)
	}
}
