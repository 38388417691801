import client from '@/services/client'

const base = '/featured/admin'

export default {
	list: () => {
		return client.get(`${base}/items`)
	},
	read: (id) => {
		return client.get(`${base}/item/${id}`)
	},
	create: (data) => {
		return client.post(`${base}/item`, data)
	},
	update: (data) => {
		return client.patch(`${base}/item`, data)
	},
	destroy: (id) => {
		return client.delete(`${base}/item/${id}`)
	}
}
