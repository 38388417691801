import client from '@/services/client'

const base = '/appliance/admin'

export default {
	list: (brandId) => {
		return client.get(`${base}/brand-images/${brandId}`)
	},
	create: (data) => {
		return client.post(`${base}/brand-image`, data)
	},
	update: (data) => {
		return client.patch(`${base}/brand-image`, data)
	},
	upload: (id, file) => {
		let formData = new FormData()
		formData.append('image', file)
		return client.post(`${base}/brand-image/${id}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
	},
	resequence: (data) => {
		return client.patch(`${base}/brand-images/resequence`, data)
	},
	remove: (id) => {
		return client.delete(`${base}/brand-image/${id}`)
	}
}
