import {beforeEdit, checkLoggedIn} from '@/router/_middleware'

const meta = {moduleName: 'Galleries', hideNav: false}

export const galleries = [
	{
		path: '/galleries',
		name: 'Galleries',
		component: () => import( '@/views/gallery/Index'),
		meta: meta,
		beforeEnter: checkLoggedIn,
	},
	{
		path: '/galleries/:id',
		component: () => import( '@/views/gallery/Edit'),
		meta: {
			...meta,
			title: 'Edit Gallery',
			action: 'gallery/gallery/readGallery',
			fallback: 'Galleries',
			hideNav: true
		},
		beforeEnter: beforeEdit,
	}
]
