import client from '@/services/client'

const base = '/featured/admin'

export default {
	list: () => {
		return client.get(`${base}/pages`)
	},
	create: (data) => {
		return client.post(`${base}/page`, data)
	},
	update: (data) => {
		return client.patch(`${base}/page`, data)
	},
	destroy: (data) => {
		return client.delete(`${base}/page/${data}`)
	},
}
