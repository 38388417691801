import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import attributes from '@/mixins/attributes'
import data from '@/mixins/data'
import actions from '@/mixins/actions'
import helpers from '@/mixins/helpers'

Vue.config.productionTip = false

Vue.mixin(attributes)
Vue.mixin(data)
Vue.mixin(actions)
Vue.mixin(helpers)

new Vue({
	router,
	store,
	vuetify,
	render: h => h(App)
}).$mount('#app')
