import client from '@/services/client'

const base = '/appliance/admin'

export default {
	list: () => {
		return client.get(`${base}/brands`)
	},
	read: (id) => {
		return client.get(`${base}/brand/${id}`)
	},
	create: (data) => {
		return client.post(`${base}/brand`, data)
	},
	update: (data) => {
		return client.patch(`${base}/brand`, data)
	},
	logo: (id, file) => {
		let formData = new FormData()
		formData.append('image', file)
		return client.post(`${base}/brand/${id}/logo`, formData, { headers: { 'Content-Type': 'multipart/form-data' } })
	},
	archive: (id) => {
		return client.delete(`${base}/brand/${id}`)
	}
}
