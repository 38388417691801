import client from '@/services/client'

const base = '/appliance/admin'

export default {

	list: () => {
		return client.get(`${base}/brand-groups`)
	},

	create: (data) => {
		return client.post(`${base}/brand-group`, data)
	},

	update: (data) => {
		return client.patch(`${base}/brand-group`, data)
	},

	delete: (id) => {
		return client.delete(`${base}/brand-group/${id}`)
	},

}
