import store from '@/store'

export const beforeEdit = (to, from, next) => {
	let id = to.params.id
	if (id === '' || id === undefined) {
		next({name: to.meta.fallback})
	}

	let li = store.getters['auth/loggedIn']
	if (!li) {
		let rt = store.getters['auth/refreshToken']
		if (!rt) {
			if (!to) {
				next({path: '/login'})
			} else if (to.path === '/login') {
				next()
			} else {
				next({path: '/login'})
			}
			return
		}

		let jwt = store.getters['auth/jwt']
		if (!jwt) {
			store.dispatch('auth/refresh').then(_ => {
				store.dispatch(to.meta.action, id).then(() => {
					next()
				}).catch(() => {
					next({name: to.meta.fallback})
				})
			}).catch(_ => {
				next({path: '/login'})
			})
		}

	} else {
		store.dispatch(to.meta.action, id).then(() => {
			next()
		}).catch(() => {
			next({name: to.meta.fallback})
		})
	}
}

export const checkLoggedIn = async (to, from, next) => {
	let rt = store.getters['auth/refreshToken']
	if (!rt) {
		if (!to) {
			next({path: '/login'})
		} else if (to.path === '/login') {
			next()
		} else {
			next({path: '/login'})
		}
		return
	}

	let jwt = store.getters['auth/jwt']
	if (!jwt) {
		store.dispatch('auth/refresh').then(_ => {
			if (!to) {
				next()
			} else if (to.path === '/login') {
				next({path: '/home'})
			} else {
				next()
			}
		}).catch(_ => {
		})
		return
	}

	next()
}

