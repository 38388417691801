import BrandClient from '@/services/appliances/brands'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const state = {
	brands: [],
	brand: {},

	listStatus: STATUS.NOT_RUN,
	readStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	uploadStatus: STATUS.NOT_RUN,
	archiveStatus: STATUS.NOT_RUN,
}

const actions = {
	listBrands: async ({commit, dispatch, getters}) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Brands'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await BrandClient.list()
			commit('SET_BRANDS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	readBrand: async ({commit, dispatch, getters}, id) => {
		if (getters['readStatus'] === STATUS.RUNNING) return

		const processName = 'Getting Brand'
		const statusName = 'SET_READ_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await BrandClient.read(id)
			commit('SET_BRAND', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createBrand: async ({commit, dispatch, getters}, data) => {
		if (getters['createStatus'] === STATUS.RUNNING) return

		const processName = 'Creating Brand'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandClient.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
			dispatch('listBrands')
		}
	},
	updateBrand: async ({commit, dispatch, getters}, data) => {
		if (getters['updateStatus'] === STATUS.RUNNING) return

		const statusName = 'SET_UPDATE_STATUS'
		commit(statusName, STATUS.RUNNING)
		const processName = 'Updating Brand'
		addProcess(dispatch, processName)
		try {
			await BrandClient.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
			dispatch('listBrands')
		}
	},
	uploadBrandLogo: ({commit, dispatch, getters}, data) => {
		if (getters['uploadStatus'] === STATUS.RUNNING) return

		const statusName = 'SET_UPLOAD_STATUS'
		commit(statusName, STATUS.RUNNING)
		const processName = 'Uploading Brand Logo'
		addProcess(dispatch, processName)
		return new Promise(async (resolve, reject) => {
			try {
				let resp = await BrandClient.logo(data.id, data.file)
				commit(statusName, STATUS.SUCCEEDED)
				resolve(resp.data.url)
			} catch (e) {
				alertAPIError(dispatch, e)
				commit(statusName, STATUS.FAILED)
				reject()
			} finally {
				removeProcess(dispatch, processName)
			}
		})
	},
	archiveBrand: async ({commit, dispatch, getters}, id) => {
		if (getters['archiveStatus'] === STATUS.RUNNING) return

		const processName = 'Archiving Brand'
		const statusName = 'SET_ARCHIVE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandClient.archive(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
			dispatch('listBrands')
		}
	},
}

const mutations = {
	SET_BRANDS: (state, payload) => state.brands = payload,
	SET_BRAND: (state, payload) => state.brand = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_READ_STATUS: (state, payload) => state.readStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_UPLOAD_STATUS: (state, payload) => state.uploadStatus = payload,
	SET_ARCHIVE_STATUS: (state, payload) => state.archiveStatus = payload,
}

const getters = {
	brands: state => state.brands,
	brand: state => state.brand,
	listStatus: state => state.listStatus,
	readStatus: state => state.readStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	uploadStatus: state => state.uploadStatus,
	archiveStatus: state => state.archiveStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
