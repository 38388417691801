import Client from '@/services/featured/item'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const state = {
	items: [],
	item: {},

	listStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	destroyStatus: STATUS.NOT_RUN,
}

const actions = {
	listItems: async ({commit, dispatch, getters}) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Featured Items'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.list()
			commit('SET_ITEMS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	read: ({commit, dispatch}, data) => {
		const processName = 'Get Featured Item'
		addProcess(dispatch, processName)
		return new Promise(async (resolve, reject) => {
			try {
				let resp = await Client.read(data)
				commit('SET_ITEM', resp.data)
				resolve()
			} catch (e) {
				alertAPIError(dispatch, e)
				reject()
			} finally {
				removeProcess(dispatch, processName)
			}
		})
	},
	create: async ({commit, dispatch, state}, data) => {
		if (state.createStatus === STATUS.RUNNING) return

		const processName = 'Create Featured Item'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	update: async ({commit, dispatch, state}, data) => {
		if (state.updateStatus === STATUS.RUNNING) return

		const processName = 'Update Featured Item'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	destroy: async ({commit, dispatch, state}, data) => {
		if (state.destroyStatus === STATUS.RUNNING) return

		const processName = 'Delete Featured Item'
		const statusName = 'SET_DESTROY_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.destroy(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
}

const mutations = {
	SET_ITEMS: (state, payload) => state.items = payload,
	SET_ITEM: (state, payload) => state.item = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_DESTROY_STATUS: (state, payload) => state.destroyStatus = payload,
}

const getters = {
	items: state => state.items,
	activeItem: state => state.item,
	listStatus: state => state.listStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	destroyStatus: state => state.destroyStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
