import client from '@/services/client'

const base = '/gallery/admin'

export default {
	list: (id) => {
		return client.get(`${base}/gallery-images/${id}`)
	},
	create: (data) => {
		return client.post(`${base}/gallery-image`, data)
	},
	update: (data) => {
		return client.patch(`${base}/gallery-image`, data)
	},
	upload: (id, file) => {
		let formData = new FormData()
		formData.append('image', file)
		return client.post(`${base}/gallery-image/${id}/upload`, formData, {headers: {'Content-Type': 'multipart/form-data'}})
	},
	resequence: (data) => {
		return client.patch(`${base}/gallery-images`, data)
	},
	destroy: (id) => {
		return client.delete(`${base}/gallery-image/${id}`)
	}
}
