import brand from '@/store/appliance/brand'
import brandGroup from '@/store/appliance/brand-group'
import brandImage from '@/store/appliance/brand-image'

export default {
	namespaced: true,
	modules: {
		brand,
		brandGroup,
		brandImage
	}
}
