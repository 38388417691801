import BrandGroupClient from '@/services/appliances/brand-groups'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const state = {
	brandGroups: [],

	listStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	deleteStatus: STATUS.NOT_RUN,
}

const actions = {
	listBrandGroups: async ({commit, dispatch, getters}) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Brand Groups'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await BrandGroupClient.list()
			commit('SET_BRAND_GROUPS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createBrandGroup: async ({commit, dispatch, getters}, data) => {
		if (getters['createStatus'] === STATUS.RUNNING) return

		const processName = 'Creating Brand Group'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandGroupClient.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	updateBrandGroup: async ({commit, dispatch, getters}, data) => {
		if (getters['updateStatus'] === STATUS.RUNNING) return

		const processName = 'Updating Brand Group'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandGroupClient.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
}

const mutations = {
	SET_BRAND_GROUPS: (state, payload) => state.brandGroups = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_DELETE_STATUS: (state, payload) => state.deleteStatus = payload,
}

const getters = {
	brandGroups: state => state.brandGroups,
	listStatus: state => state.listStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	deleteStatus: state => state.deleteStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
