import gallery from './gallery'
import galleryImage from './gallery-image'

export default {
	namespaced: true,
	modules: {
		gallery,
		galleryImage
	}
}
