/*
let base = ''

switch (process.env.NODE_ENV) {
	case 'development':
		// base = 'https://api.mhdweb.dev'
		base = 'http://api.test.mhdwebdev.com'
		break
	case 'staging':
		base = 'https://api.mhdweb.dev'
		break
	case 'production':
		base = 'https://webapi.multihousingdepot.com'
		break
}
*/

export const ADMIN_CONFIG = {
	BASE_URL: process.env.VUE_APP_API_BASE_URL,
}
