import {checkLoggedIn} from '@/router/_middleware'

const meta = {moduleName: 'Featured', hideNav: false}

export const featured = [
	{
		path: '/featured',
		component: () => import('@/views/featured/FeaturedIndex'),
		meta: meta,
		beforeEnter: checkLoggedIn,
		children: [
			{
				path: 'pages',
				component: () => import('@/views/featured/page/PageIndex'),
				meta: meta,
			},
			{
				path: '',
				redirect: 'pages',
			},
		]
	}
]
