<template>
	<v-app dark>
		<v-navigation-drawer v-if="!$route.meta.hideNav" v-model="drawer" id="SidebarNavigation"
							 :mini-variant.sync="miniDrawer" app bottom>
			<v-container>
				<v-row class="mt-4 mb-4" justify="center">
					<span>MHD Web Admin</span>
				</v-row>
			</v-container>
			<v-divider></v-divider>
			<v-list dense nav>
				<v-list-item active-class="active-module-page" link to="/home">
					<v-list-item-icon>
						<v-icon>mdi-home</v-icon>
					</v-list-item-icon>
					<v-list-item-content>
						<v-list-item-title>Home</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<template v-for="item in navItems">
					<v-list-group v-if="item.navItems" :key="item.name" :value="item.name === activeModule"
								  @click="miniDrawer = false" :prepend-icon="item.icon" active-class="active-module"
								  no-action ripple>
						<template v-slot:activator>
							<v-list-item-content>
								<v-list-item-title>{{ item.name }}</v-list-item-title>
							</v-list-item-content>
						</template>
						<template v-for="sub in item.navItems">
							<v-list-item v-if="!sub.disabled" :key="sub.name" :to="`${item.link}/${sub.link}`"
										 active-class="active-module-page" link>
								<v-list-item-content>
									<v-list-item-title>{{ sub.name }}</v-list-item-title>
								</v-list-item-content>
							</v-list-item>
						</template>
					</v-list-group>
					<v-list-item v-else :key="item.name" :to="item.link" active-class="active-module-page" link>
						<v-list-item-icon>
							<v-icon v-text="item.icon"></v-icon>
						</v-list-item-icon>
						<v-list-item-content>
							<v-list-item-title>{{ item.name }}</v-list-item-title>
						</v-list-item-content>
					</v-list-item>
				</template>
			</v-list>
			<template v-slot:append>
				<v-container fluid>
					<v-row align="center" dense justify="center" no-gutters>
						<v-spacer></v-spacer>
						<v-col class="text-center" cols="8">
							<v-menu v-if="loggedIn" offset-y top>
								<template v-slot:activator="{ on }">
									<v-btn v-on="on" class="caption text--secondary" text>
										{{ name }}
									</v-btn>
								</template>
								<v-list class="text-center">
									<v-list-item @click="logout">
										<v-list-item-title>Logout</v-list-item-title>
									</v-list-item>
								</v-list>
							</v-menu>
						</v-col>
						<v-spacer></v-spacer>
					</v-row>
				</v-container>
			</template>
		</v-navigation-drawer>

		<v-main :style="{ background: backgroundColor }">
			<v-container class="pa-0" fluid>
				<v-slide-x-transition mode="out-in">
					<router-view></router-view>
				</v-slide-x-transition>
			</v-container>
		</v-main>

		<v-footer absolute app inset>
			<v-container class="pa-0 ma-0" fluid>
				<v-row dense>
					<v-col class="text-truncate" cols="10">
						<span class="caption text--secondary">
							Copyright &copy; 2021 Multi-Housing Depot. All rights reserved.
						</span>
						<span class="caption text--secondary">
							{{ loading ? ` - ${processes}` : '' }}
						</span>
					</v-col>
					<v-spacer></v-spacer>
					<v-col v-if="loggedIn" cols="auto">
						<v-icon @click="showingFileUploader = true" small>
							mdi-cloud-upload
						</v-icon>
					</v-col>
				</v-row>
			</v-container>
		</v-footer>
		<Alert></Alert>
		<FileUploader v-if="loggedIn" :showing.sync="showingFileUploader"></FileUploader>
	</v-app>
</template>

<script>
import {EventBus} from '@/event-bus'
import {modules} from '@/modules'
import Alert from '@/components/global/Alert'
import {mapActions, mapGetters} from 'vuex'
import FileUploader from '@/components/global/FileUploader'

export default {
	name: 'App',
	components: {FileUploader, Alert},
	data: () => ({
		drawer: null,
		miniDrawer: false,
		navItems: modules,
		currentModule: 0,
		pinnedDrawer: true,
		navTransition: false,
		showingFileBrowser: false,
		showingFileUploader: false,
	}),
	created() {
		this.$vuetify.theme.dark = true
		document.getElementById('body').style.background = this.backgroundColor
		EventBus.$on('toggle-nav', () => {
			this.drawer = !this.drawer
		})
	},
	methods: {
		...mapActions('auth', [
			'logout'
		])
	},
	computed: {
		...mapGetters('auth', [
			'loggedIn',
			'name'
		]),
		...mapGetters('global/process', [
			'loading',
			'processes',
		]),
		activeModule() {
			return this.$route.meta.moduleName
		},
		backgroundColor() {
			return this.$vuetify.theme.themes['dark'].background
		},
	},
	watch: {
		loggedIn(updated) {
			if (!updated) {
				this.$router.push('/login')
			}
		}
	}
}
</script>

<style>
html {
	overflow-y: auto;
}

.v-btn {
	text-transform: unset !important;
}

.v-btn--disabled {
	pointer-events: unset !important;
	cursor: default;
}

.v-tab {
	text-transform: unset !important;
}

.active-module-page {
	color: #FFFFFF !important;
	background-color: var(--v-primary-base);
	opacity: 1 !important;
}

.active-module-page.theme--light.v-icon {
	color: #FFFFFF !important;
}

.active-module {
	color: var(--v-secondary-base) !important;
}

.active-module.theme--light.v-icon {
	color: var(--v-secondary-base) !important;
}

.theme--dark.v-list-item--active:hover::before, .theme--dark.v-list-item--active::before {
	opacity: 0 !important;
}

.theme--light.v-list-item--active:hover::before, .theme--light.v-list-item--active::before {
	opacity: 0 !important;
}

/* width */
::-webkit-scrollbar {
	width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
	/*background: rgba(241, 241, 241, 0.7);*/
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: var(--v-primary-base);
	/*background: #888;*/
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: var(--v-accent-base);
	/*background: #555;*/
}

.mobile-loading-bar {
	position: fixed;
	left: 0;
	right: 0;
	bottom: 0;
}

.clickable-text {
	cursor: pointer;
}
</style>
