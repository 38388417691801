import {checkLoggedIn} from '@/router/_middleware'

export const auth = [
	{
		path: '/login',
		name: 'Login',
		beforeEnter: checkLoggedIn,
		meta: {title: 'Login', hideNav: true},
		component: () => import( '@/views/auth/Login.vue')
	}
]
