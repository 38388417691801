import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'
import BrandImageClient from '@/services/appliances/brand-images'

const state = {
	brandImages: [],

	listStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	uploadStatus: STATUS.NOT_RUN,
	resequenceStatus: STATUS.NOT_RUN,
	removeStatus: STATUS.NOT_RUN
}

const actions = {
	clearBrandImages: ({commit}) => {
		commit('SET_BRAND_IMAGES', [])
	},
	listBrandImages: async ({commit, dispatch, getters}, brandId) => {
		if (getters['listStatus'] === STATUS.RUNNING) return

		const processName = 'Listing Brand Images'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await BrandImageClient.list(brandId)
			commit('SET_BRAND_IMAGES', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createBrandImage: async ({commit, dispatch, getters}, data) => {
		if (getters['createStatus'] === STATUS.RUNNING) return

		const processName = 'Creating Brand Image'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandImageClient.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	updateBrandImage: async ({commit, dispatch, getters}, data) => {
		if (getters['updateStatus'] === STATUS.RUNNING) return

		const processName = 'Updating Brand Image'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandImageClient.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	uploadBrandImage: async ({commit, dispatch, getters}, data) => {
		if (getters['uploadStatus'] === STATUS.RUNNING) return

		const processName = 'Uploading Brand Image'
		const statusName = 'SET_UPLOAD_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandImageClient.upload(data.id, data.file)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	resequenceBrandImages: async ({commit, dispatch, getters}, data) => {
		if (getters['resequenceStatus'] === STATUS.RUNNING) return

		const processName = 'Resequencing Brand Images'
		const statusName = 'SET_RESEQUENCE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandImageClient.resequence(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	removeBrandImage: async ({commit, dispatch, getters}, id) => {
		if (getters['removeStatus'] === STATUS.RUNNING) return

		const processName = 'Removing Brand Image'
		const statusName = 'SET_REMOVE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await BrandImageClient.remove(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	}
}

const mutations = {
	SET_BRAND_IMAGES: (state, payload) => state.brandImages = payload,
	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_UPLOAD_STATUS: (state, payload) => state.uploadStatus = payload,
	SET_RESEQUENCE_STATUS: (state, payload) => state.resequenceStatus = payload,
	SET_REMOVE_STATUS: (state, payload) => state.removeStatus = payload,
}

const getters = {
	brandImages: state => state.brandImages,
	listStatus: state => state.listStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	uploadStatus: state => state.uploadStatus,
	resequenceStatus: state => state.resequenceStatus,
	removeStatus: state => state.removeStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
