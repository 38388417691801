<template>
	<v-snackbar bottom right v-model="showing" :color="currentAlert.type" :timeout="timeout" multi-line elevation="24">
		<div v-if="currentAlert.title">
			<span class="subtitle-1">{{ currentAlert.title }}</span>
			<br>
		</div>
		<span class="subtitle-2" id="alertMessage">{{ currentAlert.message }}</span>
		<template v-slot:action="{ attrs }" v-if="currentAlert.type === 'error'">
			<v-btn text v-bind="attrs" @click="copyAlertMessage">
				Copy
			</v-btn>
		</template>
	</v-snackbar>
</template>

<script>
export default {
	name: 'Alert',
	data: () => ({
		timeout: 5000,
		showing: false,
		currentAlert: {}
	}),
	methods: {
		copyAlertMessage() {
			let alertMessageText = document.getElementById('alertMessage')
			let range = document.createRange()
			range.selectNode(alertMessageText)
			window.getSelection().removeAllRanges()
			window.getSelection().addRange(range)
			document.execCommand('copy')
			window.getSelection().removeAllRanges()
		}
	},
	computed: {
		alerts: {
			get: function () {
				return this.$store.getters['global/alert/alerts']
			},
			set: function (value) {
				this.$store.commit('global/alert/SET_ALERTS', value)
			}
		}
	},
	watch: {
		alerts() {
			if (!this.showing && this.alerts.length > 0) {
				this.currentAlert = this.alerts.shift()
				this.$nextTick(() => this.showing = true)
			}
		},
		showing() {
			if (!this.showing && this.alerts.length > 0) {
				this.currentAlert = this.alerts.shift()
				this.$nextTick(() => this.showing = true)
			}
		}
	}
}
</script>

<style scoped>

</style>
