const state = {
	processes: []
}

const actions = {
	addProcess: (context, name) => {
		context.commit('ADD_PROCESS', name)
	},
	removeProcess: (context, name) => {
		context.commit('REMOVE_PROCESS', name)
	}
}

const mutations = {
	ADD_PROCESS: (state, data) => state.processes.push(data),
	REMOVE_PROCESS: (state, data) => state.processes.splice(state.processes.indexOf(data), 1)
}

const getters = {
	loading: state => state.processes.length > 0,
	processes: state => state.processes.join(', ')
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
