import {checkLoggedIn} from '@/router/_middleware'

const meta = {moduleName: 'Blog', hideNav: false}

export const blog = [
	{
		path: '/blog',
		component: () => import( '@/views/blog/Index.vue'),
		meta: meta,
		beforeEnter: checkLoggedIn,
		children: [
			{
				path: 'posts',
				component: () => import( '@/views/blog/posts/Index'),
				name: 'Blog Posts',
				meta: {...meta, title: 'Blog Posts'},
			},
			{
				path: '',
				redirect: 'posts',
			},
		]
	}
]
