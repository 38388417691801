export const alertAPIError = (dispatch, err) => {
	if (err.response) {
		dispatch('global/alert/alertUser', {type: 'error', title: err.response.data.title, message: err.response.data.message}, { root: true })
	} else {
		console.error(err)
		dispatch('global/alert/alertUser', { type: 'error', message: 'Something went wrong... Try again in a few minutes.' }, { root: true })
	}
}

export const addProcess = (dispatch, name) => {
	dispatch('global/process/addProcess', name, {root: true})
}

export const removeProcess = (dispatch, name) => {
	dispatch('global/process/removeProcess', name, {root: true})
}
