import client from '@/services/client'

const base = '/accessory/admin'

export default {

	list: (query) => {
		return client.get(`${base}/products`, {params: query})
	},

	read: (id) => {
		return client.get(`${base}/product/${id}`)
	},

	create: (data) => {
		return client.post(`${base}/product`, data)
	},

	update: (data) => {
		return client.patch(`${base}/product`, data)
	},

	archived: () => {
		return client.get(`${base}/products/archived`)
	},

	archive: (id) => {
		return client.delete(`${base}/product/${id}/archive`)
	},

	restore: (id) => {
		return client.patch(`${base}/categproductory/${id}/restore`)
	},

}
