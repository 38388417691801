import Client from '@/services/accessory/accessory-group'
import {STATUS} from '@/store/status'
import {addProcess, alertAPIError, removeProcess} from '@/store/helpers'

const defaultFilters = {
	page: 1,
	limit: 10,
	name: null,
	archived: false,
}

const state = {
	accessoryGroups: {
		data: [],
		pages: 0,
		total: 0,
	},
	appliedFilters: {...defaultFilters},

	listStatus: STATUS.NOT_RUN,
	createStatus: STATUS.NOT_RUN,
	updateStatus: STATUS.NOT_RUN,
	archiveStatus: STATUS.NOT_RUN,
	restoreStatus: STATUS.NOT_RUN,
}

const actions = {
	applyFilters: ({commit, dispatch}, data) => {
		commit('SET_APPLIED_FILTERS', data)
		dispatch('listAccessoryGroups')
	},
	listAccessoryGroups: async ({commit, dispatch, state}) => {
		if (state.listStatus === STATUS.RUNNING) return

		const processName = 'Listing Accessory Groups'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.list(state.appliedFilters)
			commit('SET_ACCESSORY_GROUPS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	listAllAccessoryGroups: async ({commit, dispatch, state}) => {
		if (state.listStatus === STATUS.RUNNING) return

		const processName = 'Listing Accessory Groups'
		const statusName = 'SET_LIST_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			let resp = await Client.list({})
			commit('SET_ACCESSORY_GROUPS', resp.data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	createAccessoryGroup: async ({commit, dispatch, state}, data) => {
		if (state.createStatus === STATUS.RUNNING) return

		const processName = 'Creating Accessory Group'
		const statusName = 'SET_CREATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.create(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	updateAccessoryGroup: async ({commit, dispatch, state}, data) => {
		if (state.updateStatus === STATUS.RUNNING) return

		const processName = 'Updating Accessory Group'
		const statusName = 'SET_UPDATE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.update(data)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	archiveAccessoryGroup: async ({commit, dispatch, state}, id) => {
		if (state.archiveStatus === STATUS.RUNNING) return

		const processName = 'Archiving Accessory Group'
		const statusName = 'SET_ARCHIVE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.archive(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
	restoreAccessoryGroup: async ({commit, dispatch, state}, id) => {
		if (state.restoreStatus === STATUS.RUNNING) return

		const processName = 'Restoring Accessory Group'
		const statusName = 'SET_RESTORE_STATUS'
		addProcess(dispatch, processName)
		commit(statusName, STATUS.RUNNING)
		try {
			await Client.restore(id)
			commit(statusName, STATUS.SUCCEEDED)
		} catch (e) {
			alertAPIError(dispatch, e)
			commit(statusName, STATUS.FAILED)
		} finally {
			removeProcess(dispatch, processName)
		}
	},
}

const mutations = {
	SET_ACCESSORY_GROUPS: (state, payload) => state.accessoryGroups = payload,
	SET_APPLIED_FILTERS: (state, payload) => state.appliedFilters = payload,

	SET_LIST_STATUS: (state, payload) => state.listStatus = payload,
	SET_CREATE_STATUS: (state, payload) => state.createStatus = payload,
	SET_UPDATE_STATUS: (state, payload) => state.updateStatus = payload,
	SET_ARCHIVE_STATUS: (state, payload) => state.archiveStatus = payload,
	SET_RESTORE_STATUS: (state, payload) => state.restoreStatus = payload,
}

const getters = {
	accessoryGroups: state => state.accessoryGroups,
	accessoryGroupName: (state) => (id) => {
		for (let i = 0; i < state.accessoryGroups.length; i++) {
			if (state.accessoryGroups[i].id === id) {
				return state.accessoryGroups[i].name
			}
		}
		return '---'
	},
	appliedFilters: state => state.appliedFilters,

	listStatus: state => state.listStatus,
	createStatus: state => state.createStatus,
	updateStatus: state => state.updateStatus,
	archiveStatus: state => state.archiveStatus,
	restoreStatus: state => state.restoreStatus,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
