const state = {
	alerts: []
}

const actions = {
	alertUser: (context, data) => {
		context.commit('ADD_ALERT', data)
	},
	alertSuccess: (context, msg) => {
		context.commit('ADD_ALERT', { type: 'success', title: 'Success', message: msg })
	},
	alertError: (context, msg) => {
		context.commit('ADD_ALERT', { type: 'error', title: 'Error', message: msg })
	},
}

const mutations = {
	ADD_ALERT: (state, data) => state.alerts.push(data),
}

const getters = {
	alerts: state => state.alerts,
}

export default {
	namespaced: true,
	state,
	actions,
	mutations,
	getters
}
