import Vue from 'vue'
import VueRouter from 'vue-router'
import {accessories} from '@/router/accessories'
import {appliances} from '@/router/appliances'
import {auth} from '@/router/auth'
import {blog} from '@/router/blog'
import {featured} from '@/router/featured'
import {galleries} from '@/router/galleries'

Vue.use(VueRouter)

const routes = [
	...accessories,
	...appliances,
	...auth,
	...blog,
	...featured,
	...galleries,
	{
		path: '/home',
		meta: {hideNav: false},
		component: () => import( '@/views/home/Index.vue')
	},
	{
		path: '/',
		redirect: '/appliances'
	},
]

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes
})

export default router
