import client from '@/services/client'

const base = '/accessory/admin'

export default {

	list: (query) => {
		return client.get(`${base}/categories`, {params: query})
	},

	create: (data) => {
		return client.post(`${base}/category`, data)
	},

	update: (data) => {
		return client.patch(`${base}/category`, data)
	},

	archived: () => {
		return client.get(`${base}/categories/archived`)
	},

	archive: (id) => {
		return client.delete(`${base}/category/${id}/archive`)
	},

	restore: (id) => {
		return client.patch(`${base}/category/${id}/restore`)
	},

}
