import {beforeEdit, checkLoggedIn} from '@/router/_middleware'

const meta = {moduleName: 'Accessories', hideNav: false}

export const accessories = [
	{
		path: '/accessories',
		component: () => import( '@/views/accessories/Index'),
		meta: meta,
		beforeEnter: checkLoggedIn,
		children: [
			{
				path: 'accessory-groups',
				component: () => import( '@/views/accessories/accessory-groups/Index'),
				name: 'Accessory Groups',
				meta: {...meta, title: 'Accessory Groups'},
			},
			{
				path: 'categories',
				component: () => import( '@/views/accessories/categories/Index'),
				name: 'Categories',
				meta: {...meta, title: 'Categories'},
			},
			{
				path: 'products',
				component: () => import( '@/views/accessories/products/Index'),
				name: 'Products',
				meta: {...meta, title: 'Products'},
			},
			{
				path: '',
				redirect: 'accessory-groups',
			},
		]
	},
	{
		path: '/accessories/product/:id',
		component: () => import( '@/views/accessories/products/Edit.vue'),
		meta: {...meta, action: 'accessory/product/readProduct', fallback: 'Products', hideNav: true},
		name: 'Edit Product',
		beforeEnter: beforeEdit,
	}
]
