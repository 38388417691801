<template>
	<v-dialog v-model="showing" max-width="1200" persistent @keydown.esc="$emit('update:showing', false)">
		<v-card>
			<v-toolbar dark flat short>
				<v-toolbar-title>File Uploader {{ folder ? ' - ' + folder.fullPath : '' }}</v-toolbar-title>
				<v-spacer></v-spacer>
				<v-btn :disabled="!folder || uploadedFiles.length === 0" @click="uploadFiles" class="mr-2"
					   color="primary">
					<v-icon>mdi-upload</v-icon>
				</v-btn>
				<v-btn @click="$emit('update:showing', false)">
					<v-icon>mdi-close</v-icon>
				</v-btn>
			</v-toolbar>
			<v-card-text>
				<v-row dense>
					<v-spacer></v-spacer>

					<v-col class="overflow-auto" cols="4" style="max-height: calc(90vh - 80px);">
						<file-tree :items="roots" @openChanged="updateOpen" class="mt-2"></file-tree>
					</v-col>

					<v-col class="overflow-auto" cols="8" style="max-height: calc(90vh - 80px);">
						<v-row v-if="folder" dense>
							<v-col cols="12">
								<v-col cols="12">
									<v-text-field v-model="subFolder" append-outer-icon="mdi-plus" autocomplete="off"
												  dense hide-details label="Add Sub Folder" outlined
												  @click:append-outer="addSubFolder"
												  @keydown.enter="addSubFolder"></v-text-field>
								</v-col>
								<v-col cols="12">
									<template v-for="sf in subFolders">
										<v-chip close small @click:close="removeSubFolder(sf)">
											{{ sf }}
										</v-chip>
										/
									</template>
								</v-col>
							</v-col>
							<v-col cols="12">
								<v-card outlined @dragenter.prevent="dragover = true"
										@dragleave.prevent="dragover = false" @dragover.prevent="dragover = true"
										@drop.prevent="filesDropped($event)">
									<v-card-text>
										<v-row dense justify="center">
											<v-spacer></v-spacer>
											<v-col cols="auto">
												<v-icon :class="[dragover ? 'mt-5' : 'mt-2, mb-3']" size="60">
													mdi-cloud-upload
												</v-icon>
											</v-col>
											<v-spacer></v-spacer>
										</v-row>
										<v-row dense justify="center">
											<v-col v-if="uploadedFiles.length === 0" cols="auto">
												<p>Drop your file(s) here.</p>
											</v-col>
											<v-col v-else cols="12">
												<v-list :disabled="uploadStatus === status.RUNNING" two-line>
													<v-list-item v-for="(uf, idx) in uploadedFiles" :key="uf.name">
														<v-list-item-content>
															<v-list-item-title v-text="uf.name"></v-list-item-title>
															<v-list-item-subtitle
																v-text="calculateSize(uf.size)"></v-list-item-subtitle>
														</v-list-item-content>
														<v-list-item-action>
															<v-icon @click="removeFile(idx)">mdi-close</v-icon>
														</v-list-item-action>
													</v-list-item>
												</v-list>
											</v-col>
										</v-row>
									</v-card-text>
								</v-card>
							</v-col>
						</v-row>
					</v-col>
					<v-spacer></v-spacer>
				</v-row>
			</v-card-text>
		</v-card>
	</v-dialog>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'
import FileTree from '@/components/global/FileTree'

export default {
	name: 'FileUploader',
	components: {FileTree},
	props: {showing: {type: Boolean, required: true}},
	data: () => ({
		openFolders: [],
		folder: null,
		dragover: false,
		uploadedFiles: [],
		subFolder: '',
		subFolders: []
	}),
	created() {
		this.listRoots()
	},
	mounted() {
		this._keyListener = function (e) {
			if (this.mobileLayout) {
				return
			}
			if (e.ctrlKey && e.altKey && e.code === 'KeyU') {
				this.$emit('update:showing', !this.showing)
			}
		}
		document.addEventListener('keydown', this._keyListener.bind(this))
	},
	beforeDestroy() {
		document.removeEventListener('keydown', this._keyListener)
	},
	methods: {
		...mapActions('media/file', [
			'getMeta',
			'listRoots',
			'listDetails',
			'upload'
		]),
		updateOpen(item) {
			this.folder = item
		},
		addSubFolder() {
			if (!this.subFolder) return
			if (!this.subFolder.trim()) return
			this.subFolders.push(this.subFolder)
			this.subFolder = ''
		},
		removeSubFolder(sf) {
			const idx = this.subFolders.indexOf(sf)
			if (idx === -1) return
			this.subFolders.splice(idx, 1)
		},
		filesDropped(e) {
			this.uploadedFiles = []
			for (let i = 0; i < e.dataTransfer.files.length; i++) {
				this.uploadedFiles.push(e.dataTransfer.files[i])
			}
		},
		removeFile(idx) {
			this.uploadedFiles.splice(idx, 1)
		},
		calculateSize(size) {
			if (size <= 100000) {
				return `${(size / 1024).toFixed(2)}KB`
			}
			return `${(size / (1024 * 1024)).toFixed(2)}MB`
		},
		uploadFiles() {
			if (this.uploadedFiles.length === 0) return

			let uploadPath = this.folder.fullPath
			if (this.subFolders.length > 0) {
				uploadPath = this.pathJoin([uploadPath, ...this.subFolders])
			}
			this.upload({uploadPath: uploadPath, files: this.uploadedFiles})
		},
		pathJoin(parts, sep) {
			const separator = sep || '/'
			const replace = new RegExp(separator + '{1,}', 'g')
			return parts.join(separator).replace(replace, separator)
		}
	},
	computed: {
		...mapGetters('media/file', [
			'meta',
			'roots',
			'uploadStatus',
			'uploadErrors'
		]),
	},
	watch: {
		showing(updated) {
			if (!updated) {
				this.uploadedFiles = []
				this.subFolders = []
				this.subFolder = ''
			}
		}
	}
}
</script>

<style scoped>

</style>
