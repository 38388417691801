import {mapActions} from 'vuex'

export default {
	methods: {
		...mapActions('global/alert', [
			'alertUser',
			'alertError',
			'alertSuccess'
		])
	},
}
