import Vue from 'vue'
import Vuex from 'vuex'
import accessory from '@/store/accessory'
import appliance from '@/store/appliance'
import auth from '@/store/auth'
import featured from '@/store/featured'
import gallery from '@/store/gallery'
import global from '@/store/global'
import media from '@/store/media'


Vue.use(Vuex)

export default new Vuex.Store({
	modules: {
		accessory,
		appliance,
		auth,
		featured,
		gallery,
		global,
		media,
	}
})
