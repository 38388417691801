import {STATUS} from '@/store/status'

export default {
	data: () => ({
		status: STATUS,
		validation: {
			required: value => !!value || 'Required.',
			notEmpty: value => value?.match(/^ *$/) === null || 'Cannot be empty.',
			maxSize: value => value?.size <= 5242880 || 'The maximum file size is 5MB.'
		},
	})
}
